import React from 'react'
import Image from 'next/image'

import dynamic from 'next/dynamic';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));
const CountUp = dynamic(() => import('react-countup'));
function About({about}:any) {

  return (
    <section className="sections lightgray">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6" data-aos="fade" data-aos-delay="100">
                    <div className="about_img fit_img">
                        <Image src={about?.aboutImage?.sourceUrl} width={500} height={600} alt={about?.aboutImage?.altText}  loading='lazy'/>
                    </div>
                </div>
                <div className="col-lg-6" data-aos="fade" data-aos-delay="200">
                    <h2>{about?.title}</h2>
                    <div className='about_content_area' dangerouslySetInnerHTML={{ __html: about?.description  || '' }}></div>
                    <div className="btn_holder">
                        <CommanBtn href={`${about?.button?.url}`} className="" text={about?.button?.title} />
                    </div>
                </div>
            </div>

            <div className="counter_row row extra_space">
                {
                    about?.achievements && about?.achievements.map((achive:any,index:any)=>{
                        return(
                            <div key={index} className="col-md-4" data-aos="fade" data-aos-delay="100">
                                <div className="counter_box">
                                    <h3>{achive?.title}</h3>
                                    <span className="count_digit"><CountUp enableScrollSpy={true}  end={achive?.achiveData} /></span>
                                    {/* <span className="counter" data-count="1000000"></span> */}
                                    <p>{achive?.descriptive}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
        <div className="animated_box"></div>
    </section>
  )
}

export default About
